import React from "react";
import { parameterHelper } from "../../helpers/parameterHelper";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useDocumentMetadata } from "../../fetch/fetchMetadata";
import DropMenu from "../nav/DropMenu";
import useIsSinglePage from "../../hooks/useIsSinglePage";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useLanguageList } from "../../fetch/fetchClassificationItems";
import { logFeatureUsage } from "../../fetch/insightsHelper";

const defaultParameters = { page: -1 };
export default function NavBarSectionSelect() {
    const navigate = useNavigate();
    const location = useLocation();
    const { isSinglePageMode } = useIsSinglePage();
    const { partNumber } = useParams();
    const { page } = parameterHelper.getUrlParameters(defaultParameters);

    const { languages } = useLanguageList();
    const { metadata, isLoading: isMetadataLoading } = useDocumentMetadata(partNumber);
    const { hasCover, outline, translations, language } = metadata;

    if (isMetadataLoading) {
        return null;
    }

    let filteredChildren = [];
    if (!!outline && !!outline.children && outline.children.length > 0) {
        filteredChildren = outline.children.filter(it => !!it.pageNumber && !!it.title);
    }

    if (filteredChildren.length === 1 && !!filteredChildren[0].children &&
        filteredChildren[0].children.length > 0) {
        filteredChildren = filteredChildren[0].children.filter(it => !!it.pageNumber && !!it.title);
    }

    const hasTocButton = filteredChildren.length > 1;
    const hasLanguageButton = !!translations && !!languages && translations.length > 1;

    const currentPage = hasCover ? page : page < 1 ? 1 : page;

    let rootLabel = 'Cover';
    if (currentPage == 0) {
        rootLabel = 'Manual Outline';
    }
    if (currentPage > 0 && hasTocButton) {
        const searchingPage = hasCover ? currentPage + 1 : currentPage;
        for (let i = 0; i < filteredChildren.length; i++) {
            if (searchingPage >= filteredChildren[i].pageNumber) {
                rootLabel = filteredChildren[i].title;

                const nextIdx = i + 1;
                if (nextIdx < filteredChildren.length &&
                    filteredChildren[nextIdx].pageNumber > searchingPage) {

                    if (filteredChildren[nextIdx].pageNumber == (searchingPage + 1) && !isSinglePageMode) {
                        rootLabel += `/${filteredChildren[nextIdx].title}`;
                    }

                    break;
                }
            }
        }
    }

    const handlePageClick = (pageNumber) => {
        pageNumber = hasCover ? pageNumber - 1 : pageNumber;
        const params = new URLSearchParams(location.search);
        const isLeftPage = pageNumber % 2 > 0;

        params.set('page', isLeftPage || isSinglePageMode ? pageNumber : pageNumber - 1);
        if (pageNumber == (hasCover ? defaultParameters.page : 1)) {
            params.delete('page');
        }

        navigate(`${location.pathname}?${params.toString()}`);
    }

    return (
        <>
            {hasTocButton ? (
                <DropMenu
                    label={(
                        <>
                            {rootLabel}
                            <ArrowDropDownIcon />
                        </>
                    )}
                    subItems={[
                        ...hasCover ? [{
                            label: 'Cover', onClick: () => {
                                handlePageClick(0);
                            }
                        }] : [],
                        ...hasCover && isSinglePageMode ? [{
                            label: 'Manual Outline', onClick: () => {
                                handlePageClick(1);
                            }
                        }] : [],
                        ...filteredChildren.map(item => {
                            return {
                                label: item.title,
                                onClick: () => {
                                    logFeatureUsage('NavTOC');
                                    handlePageClick(item.pageNumber);
                                }
                            };
                        })
                    ]}
                />
            ) : null}
            {hasLanguageButton ? (
                <DropMenu
                    label={(
                        <>
                            {languages.filter(it => it.code == language)[0]?.nameLocal}
                            <ArrowDropDownIcon />
                        </>
                    )}
                    subItems={[
                        ...translations.map(item => {
                            return {
                                label: languages.filter(it => it.code == item.language)[0]?.nameLocal,
                                onClick: () => {
                                    logFeatureUsage('NavLanguage');
                                    navigate(`/literature/view/${item.partNumber}`);
                                }
                            };
                        })
                    ]}
                />
            ) : null}
        </>
    );
}