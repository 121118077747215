import React, { useEffect, useState } from "react";
import { Button, Paper } from "@mui/material";
import { HorizontalDivider, HorizontalList, HorizontalListItem } from "../../HorizontalList";

import TocIcon from '@mui/icons-material/Toc';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import LastPageIcon from '@mui/icons-material/LastPage';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import FindInPageIcon from '@mui/icons-material/FindInPage';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import DescriptionIcon from '@mui/icons-material/Description';
import FitScreenIcon from '@mui/icons-material/FitScreen';
import ExpandIcon from '@mui/icons-material/Expand';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import RotateRightIcon from '@mui/icons-material/RotateRight';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { useDispatch } from "react-redux";
import { setExpandMode, setSinglePageMode } from "../../../redux/documentViewSlice";
import { enterFullScreen, exitFullScreen, isInFullScreen } from "../../../helpers/fullScreenHelper";
import { useIsSinglePage, usePhoneMode } from "../../../hooks";
import DocumentViewMenuPageMarker from "./DocumentViewMenuPageMarker";
import { logFeatureUsage } from "../../../fetch/insightsHelper";
import JumpToPageDialog from "../JumpToPageDialog";
import useDocumentViewMenuLabels from "./useDocumentViewMenuLabels";

export default function DocumentViewMenu({
    setPageNumber, currentPage, pageCount, showToc, onToggleToc, onToggleSearch, hasCover,
    onClickZoom, showingZoom, zoomRotation, setZoomRotation, zoomScale, setZoomScale, pageMappings }) {
    const [fullScreen, setFullScreen] = useState(false);
    const [jumpModalOpen, setJumpModalOpen] = useState(false);

    const {
        tocTextLabel, singlePageViewLabel, twoPageViewLabel, fullScreenLabel, exitFullScreenLabel,
        expandViewLabel, fitToViewLabel, searchLabel, zoomLabel, closeZoomLabel, rotateLeftLabel,
        rotateRightLabel, zoomInLabel, zoomOutLabel
    } = useDocumentViewMenuLabels(showingZoom);

    const dispatch = useDispatch();
    const { isMobile, isLandscape } = usePhoneMode();
    const { isSinglePageMode, isExpandMode } = useIsSinglePage();

    const handleToggleSinglePageMode = () => {
        if (!isSinglePageMode) {
            //Setting to single page mode
            logFeatureUsage('ViewSinglePage');
        } else {
            logFeatureUsage('ViewDoublePage');
        }
        dispatch(setSinglePageMode(!isSinglePageMode))
    }

    const handleToggleExpandMode = () => {
        if (!isExpandMode) {
            //Setting to expand mode
            logFeatureUsage('ViewExpand');
        } else {
            logFeatureUsage('ViewFitToScreen');
        }
        dispatch(setExpandMode(!isExpandMode))
    }

    useEffect(() => {
        setTimeout(() => {
            setFullScreen(isInFullScreen());
        }, 250);
    }, []);

    const previousPage = currentPage - (isSinglePageMode ? 1 : 2);
    const nextPage = currentPage + (isSinglePageMode ? 1 : 2);

    const handleRotate = (newZoomRotation) => {
        if (Math.abs(newZoomRotation) >= 360) {
            newZoomRotation = 0;
        }
        if (newZoomRotation == -90) {
            newZoomRotation = 270;
        }

        setZoomRotation(newZoomRotation);
    }

    const handleZoom = (newZoomScale) => {
        newZoomScale = Math.round(newZoomScale * 10) / 10;
        if (newZoomScale < 0.1) {
            newZoomScale = 0.1;
        }

        setZoomScale(newZoomScale);
    }

    const handleFirstPageClick = () => {
        const newPageNumber = hasCover ? -1 : 1;
        if (newPageNumber <= 0 && showingZoom && hasCover) {
            onClickZoom();
        }
        setPageNumber(newPageNumber)
        logFeatureUsage('MenuPageNav');
    }

    const handlePreviousPageClick = () => {
        if (previousPage <= 0 && showingZoom && hasCover) {
            onClickZoom();
        }
        setPageNumber(previousPage)
        logFeatureUsage('MenuPageNav');
    }

    const handleShowJumpModal = () => {
        setJumpModalOpen(true);
    }

    const handleHideJumpModal = () => {
        setJumpModalOpen(false);
    }

    return (
        <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0, zIndex: 9999 }} elevation={3}>
            <JumpToPageDialog
                open={jumpModalOpen}
                onClose={handleHideJumpModal}
                onNavigate={setPageNumber}
                pageMappings={pageMappings}
                pageCount={pageCount}
                hasCover={hasCover}
            />
            <HorizontalList>
                <HorizontalListItem>
                    <Button
                        onClick={handleFirstPageClick}
                        disabled={hasCover ? currentPage < 0 : currentPage <= 1}
                        title='First Page'
                    >
                        <FirstPageIcon />
                    </Button>
                </HorizontalListItem>
                <HorizontalListItem>
                    <Button
                        onClick={() => handlePreviousPageClick(previousPage)}
                        disabled={hasCover ? currentPage < 0 : currentPage <= 1}
                        title='Previous Page'
                    >
                        <NavigateBeforeIcon />
                    </Button>
                </HorizontalListItem>
                <DocumentViewMenuPageMarker
                    currentPage={currentPage}
                    pageCount={pageCount}
                    hasCover={hasCover}
                    onShowJumpModal={handleShowJumpModal}
                />
                <HorizontalListItem>
                    <Button
                        onClick={() => {
                            setPageNumber(nextPage);
                            logFeatureUsage('MenuPageNav');
                        }}
                        disabled={nextPage > pageCount}
                        title='Next Page'
                    >
                        <NavigateNextIcon />
                    </Button>
                </HorizontalListItem>
                <HorizontalListItem>
                    <Button
                        onClick={() => {
                            setPageNumber(pageCount);
                            logFeatureUsage('MenuPageNav');
                        }}
                        disabled={nextPage > pageCount}
                        title='Last Page'
                    >
                        <LastPageIcon />
                    </Button>
                </HorizontalListItem>
                {showToc && !showingZoom && !isMobile ? (
                    <>
                        <HorizontalDivider />
                        <HorizontalListItem>
                            <Button
                                title='Table of Contents'
                                onClick={onToggleToc}
                            >
                                <TocIcon />
                                {tocTextLabel}
                            </Button>
                        </HorizontalListItem>
                    </>
                ) : null}
                <HorizontalDivider />
                {!showingZoom && !isMobile ? (
                    <>
                        <HorizontalListItem>
                            <Button
                                title='Search'
                                onClick={onToggleSearch}
                            >
                                <FindInPageIcon />
                                {searchLabel}
                            </Button>
                        </HorizontalListItem>
                    </>) : null}
                {!showingZoom && (!isMobile || isLandscape) ? (
                    <>
                        <HorizontalDivider />
                        <HorizontalListItem>
                            <Button
                                title={isSinglePageMode ? 'Side By Side View' : 'Single Page View'}
                                onClick={handleToggleSinglePageMode}
                            >
                                {isSinglePageMode ? (
                                    <MenuBookIcon />
                                ) : (
                                    <DescriptionIcon />
                                )}
                                {isSinglePageMode ? twoPageViewLabel : singlePageViewLabel}
                            </Button>
                        </HorizontalListItem>
                        {!isMobile && !isSinglePageMode ? (
                            <HorizontalListItem>
                                <Button
                                    title={isExpandMode ? 'Fit To View' : 'Expand View'}
                                    onClick={handleToggleExpandMode}
                                >
                                    {isExpandMode ? (
                                        <FitScreenIcon />
                                    ) : (
                                        <ExpandIcon />
                                    )}
                                    {isExpandMode ? fitToViewLabel : expandViewLabel}
                                </Button>
                            </HorizontalListItem>
                        ) : null}
                    </>) : null}
                {!isMobile && !showingZoom && !isSinglePageMode ? (
                    <HorizontalListItem>
                        <Button
                            title={'Zoom View'}
                            onClick={() => {
                                handleRotate(0);
                                handleZoom(1);
                                onClickZoom();
                                logFeatureUsage('ViewZoom');
                            }}
                            disabled={hasCover ? currentPage <= 0 : false}
                        >
                            <ZoomInIcon />
                            {zoomLabel}
                        </Button>
                    </HorizontalListItem>
                ) : null}
                {showingZoom && !isMobile ? (
                    <>
                        <HorizontalListItem>
                            <Button
                                title={'Rotate Left'}
                                onClick={() => {
                                    handleRotate(zoomRotation - 90);
                                    logFeatureUsage('ZoomRotate');
                                }}
                            >
                                <RotateLeftIcon />
                                {rotateLeftLabel}
                            </Button>
                        </HorizontalListItem>
                        <HorizontalListItem>
                            <Button
                                title={'Rotate Right'}
                                onClick={() => {
                                    handleRotate(zoomRotation + 90);
                                    logFeatureUsage('ZoomRotate');
                                }}
                            >
                                <RotateRightIcon />
                                {rotateRightLabel}
                            </Button>
                        </HorizontalListItem>
                        <HorizontalListItem>
                            <Button
                                title={'Zoom In'}
                                onClick={() => {
                                    handleZoom(zoomScale + .25);
                                    logFeatureUsage('ZoomScale');
                                }}
                            >
                                <AddIcon />
                                {zoomInLabel}
                            </Button>
                        </HorizontalListItem>
                        <HorizontalListItem>
                            <Button
                                title={'Zoom Out'}
                                onClick={() => {
                                    handleZoom(zoomScale - .25);
                                    logFeatureUsage('ZoomScale');
                                }}
                            >
                                <RemoveIcon />
                                {zoomOutLabel}
                            </Button>
                        </HorizontalListItem>
                        <HorizontalListItem>
                            <Button
                                title={'Close Zoom View'}
                                onClick={() => {
                                    handleRotate(0);
                                    handleZoom(1);
                                    onClickZoom();
                                }}
                                disabled={hasCover ? currentPage <= 0 : false}
                            >
                                <ZoomOutIcon />
                                {closeZoomLabel}
                            </Button>
                        </HorizontalListItem>
                    </>) : null}
                {!isMobile ? (
                    <>
                        <HorizontalDivider />
                        <HorizontalListItem>
                            <Button
                                onClick={() => {
                                    setFullScreen(!fullScreen);
                                    if (!fullScreen) {
                                        logFeatureUsage('ViewFullScreen');
                                        enterFullScreen();
                                    } else {
                                        exitFullScreen();
                                    }
                                }}
                                title={!fullScreen ? 'Full Screen' : 'Exit Full Screen'}
                            >
                                {!fullScreen ? <FullscreenIcon /> : <FullscreenExitIcon />}
                                {!fullScreen ? fullScreenLabel : exitFullScreenLabel}
                            </Button>
                        </HorizontalListItem>
                    </>) : null}
            </HorizontalList>
        </Paper >
    );
}