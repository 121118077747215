import React, { useEffect, useRef } from "react";
import ImagePageContainer from "./ImagePageContainer";
import { DocumentTableOfContentsPage } from "../../documentViewCore";

export default function ScrollViewTocPage({ hasCover, outline, documentDimensions, pageRatio, setPageNumber }) {
    const ref = useRef(null);

    useEffect(() => {
        if (!!ref.current) {
            ref.current.id = `page=0`
        }
    }, [ref?.current]);

    if (!hasCover) {
        return null;
    }

    return (
        <ImagePageContainer
            documentDimensions={documentDimensions}
            ref={ref}
        >
            <DocumentTableOfContentsPage
                documentWidth={documentDimensions.documentWidth}
                documentHeight={documentDimensions.documentHeight}
                outline={outline}
                setPageNumber={(pageNumber) => setPageNumber(pageNumber)}
                pageRatio={pageRatio}
            />
        </ImagePageContainer>
    );
}