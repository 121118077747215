import useSWR, { preload } from 'swr';
import useSWRInfinite from 'swr/infinite'
import { useDebounce } from 'use-debounce';
import { httpResponseToJSON } from '../helpers/fetchHelpers';
import { useSelector } from 'react-redux';
import { useEffect, useMemo } from 'react';
import { logListView } from './insightsHelper';
import { getAuthenticatedHeaders } from './fetchHeaders';

export const useDocumentList = (listParams) => {
    const { data, error, isValidating, mutate } = useSWR(
        getDocumentListPath(listParams),
        documentListFetcher,
        swrConfig);

    const documents = data || [];

    return { documents, error, isValidating, mutate };
};

export const usePreloadDocumentListCounts = () => {
    const {
        families,
        group: businessGroup,
        searchTextBoxValue: query,
        language,
        state,
        year
    } = useSelector((state) => state.libraryFilters);
    const businessGroups = useSelector((state) => state.permissions.businessGroups)
    const userLanguage = useSelector((state) => state.permissions.language);

    let selectedBusinessGroups = businessGroup;
    if (selectedBusinessGroups.length == 0) {
        selectedBusinessGroups = businessGroups.map(it => it.id.toString());
    }

    const listParams = {
        query,
        families,
        businessGroup: selectedBusinessGroups,
        language: language || userLanguage,
        state,
        year
    };

    useEffect(() => {
        preload(getDocumentListCountsPath(listParams), documentListFetcher);
    }, [JSON.stringify(listParams)]);
}

export const useDocumentListCounts = () => {
    const {
        families,
        group: businessGroup,
        searchTextBoxValue: query,
        language,
        state,
        year
    } = useSelector((state) => state.libraryFilters);
    const businessGroups = useSelector((state) => state.permissions.businessGroups)
    const userLanguage = useSelector((state) => state.permissions.language);

    let selectedBusinessGroups = businessGroup;
    if (selectedBusinessGroups.length == 0) {
        selectedBusinessGroups = businessGroups.map(it => it.id.toString());
    }

    const listParams = {
        query,
        families,
        businessGroup: selectedBusinessGroups,
        language: language || userLanguage,
        state,
        year
    };

    const { data, error, isValidating, isLoading, mutate } = useSWR(
        getDocumentListCountsPath(listParams),
        documentListFetcher,
        swrConfig);

    const {
        allManualTypeCount,
        installationManualCount,
        operationManualCount,
        serviceBulletinCount,
        serviceManualCount,
        totalManualCount,
        colorDiagramCount,
        diagnosticManualCount,
        videoCount,
        otherCount
    } = data || {
        allManualTypeCount: 0,
        installationManualCount: 0,
        operationManualCount: 0,
        serviceBulletinCount: 0,
        serviceManualCount: 0,
        totalManualCount: 0,
        colorDiagramCount: 0,
        diagnosticManualCount: 0,
        videoCount: 0,
        otherCount: 0
    };

    return {
        allManualTypeCount,
        installationManualCount,
        operationManualCount,
        serviceBulletinCount,
        serviceManualCount,
        totalManualCount,
        colorDiagramCount,
        diagnosticManualCount,
        videoCount,
        otherCount,
        error, isValidating, isLoading, mutate
    };
}

export const usePreloadBulletinYears = () => {
    const {
        families,
        group: businessGroup,
        searchTextBoxValue: query,
        language,
        state
    } = useSelector((state) => state.libraryFilters);
    const businessGroups = useSelector((state) => state.permissions.businessGroups)
    const userLanguage = useSelector((state) => state.permissions.language);

    let selectedBusinessGroups = businessGroup;
    if (!!selectedBusinessGroups && selectedBusinessGroups.length == 0) {
        selectedBusinessGroups = businessGroups.map(it => it.id.toString());
    }

    const listParams = {
        query,
        families,
        businessGroup: selectedBusinessGroups,
        language: language || userLanguage,
        state,
        documentType: 2
    };

    useEffect(() => {
        preload(getDocumentYears(listParams), documentListFetcher);
    }, [JSON.stringify(listParams)]);
}

export const useBulletinYears = () => {
    const {
        families,
        group: businessGroup,
        searchTextBoxValue: query,
        language,
        state
    } = useSelector((state) => state.libraryFilters);
    const businessGroups = useSelector((state) => state.permissions.businessGroups)
    const userLanguage = useSelector((state) => state.permissions.language);

    let selectedBusinessGroups = businessGroup;
    if (selectedBusinessGroups.length == 0) {
        selectedBusinessGroups = businessGroups.map(it => it.id.toString());
    }

    const listParams = {
        query,
        families,
        businessGroup: selectedBusinessGroups,
        language: language || userLanguage,
        state,
        documentType: 2
    };

    const { data: items, error, isValidating, isLoading, mutate } = useSWR(
        getDocumentYears(listParams),
        documentListFetcher,
        swrConfig);

    return {
        items, error, isValidating, isLoading, mutate
    };
}

export const useDocumentListInfinate = (take, listView) => {
    const {
        families,
        type: documentType,
        group: businessGroup,
        searchTextBoxValue,
        language,
        state,
        year
    } = useSelector((state) => state.libraryFilters);
    const [query, callback] = useDebounce(searchTextBoxValue, 750);
    const businessGroups = useSelector((state) => state.permissions.businessGroups)
    const userLanguage = useSelector((state) => state.permissions.language);

    let selectedBusinessGroups = businessGroup;
    if (selectedBusinessGroups.length == 0) {
        selectedBusinessGroups = businessGroups.map(it => it.id.toString());
    }

    const listParams = {
        query,
        families,
        documentType,
        businessGroup: selectedBusinessGroups,
        language: language || userLanguage,
        take,
        state,
        year
    };

    useMemo(() => {
        logListView(listView, documentType, query, families.join(','), selectedBusinessGroups.join(','), year)
    }, [listView, query, families.join(','), documentType, selectedBusinessGroups.join(','), year]);

    const { data, error, isLoading, isValidating, mutate, size, setSize } = useSWRInfinite(
        (pageIndex, previousPageData) => getKey(pageIndex, previousPageData, listParams),
        documentListFetcher,
        { ...swrConfig, initialSize: 1, revalidateFirstPage: false });

    let totalRecords = 0;
    let installationManualCount = 0;
    let operationManualCount = 0;
    let serviceBulletinCount = 0;
    let serviceManualCount = 0;
    let totalManualCount = 0;
    let documents = [];

    if (!!data) {
        totalRecords = data[0].totalRecords;
        installationManualCount = data[0].installationManualCount;
        operationManualCount = data[0].operationManualCount;
        serviceBulletinCount = data[0].serviceBulletinCount;
        serviceManualCount = data[0].serviceManualCount;
        totalManualCount = data[0].totalManualCount;
        documents = data.reduce((accumulator, currentValue) => accumulator.concat(currentValue.items), []);
    }

    return {
        documents, totalRecords, error, isLoading, isValidating, mutate, size, setSize,
        installationManualCount, operationManualCount, serviceBulletinCount, serviceManualCount, totalManualCount
    };
}

const getKey = (pageIndex, previousPageData, listParams) => {
    const { take, query, families, documentType, businessGroup, language, state, year } = listParams;

    if (!businessGroup || businessGroup.length == 0) {
        return null;
    }

    const path = `/document`;
    const params = new URLSearchParams('')
    params.set('take', take);

    if (!!query) {
        params.set('query', query);
    }
    if (!!families) {
        params.set('familyName', families.join(','));
    }
    if (!!documentType) {
        params.set('documentType', documentType);
    }
    if (!!businessGroup) {
        params.set('businessGroup', businessGroup);
    }
    if (!!language) {
        params.set('language', language);
    }
    if (!!state) {
        params.set('state', state);
    } else {
        params.set('state', 3);
    }
    if (!!year) {
        params.set('year', year);
    }

    if (previousPageData && !previousPageData.items.length) return null // reached the end

    params.set('skip', pageIndex * take)
    return `${path}?${params.toString()}`                    // SWR key
}

const getDocumentListPath = (listParams) => {
    const { take, families, documentType, businessGroup, year } = listParams;
    const path = `/document`;
    const params = new URLSearchParams('')
    params.set('take', take);

    if (!!families) {
        params.set('familyName', families.join(','));
    }
    if (!!documentType) {
        params.set('documentType', documentType);
    }
    if (!!businessGroup) {
        params.set('businessGroup', businessGroup);
    }
    if (!!year) {
        params.set('year', year);
    }

    return `${path}?${params.toString()}`;
}

const getDocumentListCountsPath = (listParams) => {
    const { query, families, businessGroup, language, state } = listParams;

    if (!businessGroup || businessGroup.length == 0) {
        return null;
    }

    const path = `/document/counts`;
    const params = new URLSearchParams('')

    if (!!query) {
        params.set('query', query);
    }
    if (!!families) {
        params.set('familyName', families.join(','));
    }
    if (!!businessGroup) {
        params.set('businessGroup', businessGroup);
    }
    if (!!language) {
        params.set('language', language);
    }
    if (!!state) {
        params.set('state', state);
    } else {
        params.set('state', 3);
    }

    return `${path}?${params.toString()}`;
}

const getDocumentYears = (listParams) => {
    const { query, families, businessGroup, language, state, documentType } = listParams;

    if (!businessGroup || businessGroup.length == 0) {
        return null;
    }

    const path = `/document/type/years`;
    const params = new URLSearchParams('')

    if (!!query) {
        params.set('query', query);
    }
    if (!!families) {
        params.set('familyName', families.join(','));
    }
    if (!!businessGroup) {
        params.set('businessGroup', businessGroup);
    }
    if (!!documentType) {
        params.set('documentType', documentType);
    }
    if (!!language) {
        params.set('language', language);
    }
    if (!!state) {
        params.set('state', state);
    } else {
        params.set('state', 3);
    }

    return `${path}?${params.toString()}`;
}

const documentListFetcher = (url) => {
    return getAuthenticatedHeaders().then((headers) => {
        return fetch(url, { headers })
            .then(response => httpResponseToJSON(response, 'Error loading document list'/*, showError*/))
    });
}


const swrConfig = {
    revalidateOnFocus: false,
    onErrorRetry: (error, key, config, revalidate, { retryCount }) => {
        // Never retry on 4XX error.
        if (error.status >= 400 && error.status < 500) return;

        //   // Never retry for a specific key.
        //   if (key === '/api/user') return

        // Only retry up to 1 times.
        if (retryCount > 1) return;

        // Retry after 1 seconds.
        setTimeout(() => { revalidate({ retryCount }); }, 1000)
    }
};