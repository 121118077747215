import React, { useEffect, useRef } from "react";
import { useIsInViewport } from "../../../hooks";
import DocumentScrollPagePane from "./DocumentScrollPagePane";

export default function ScrollSectionPagePane({ partNumber, pageNumber, pageCount, documentDimensions, isSectionInViewport, onComeIntoVisibility, setPageNumber, children, onToggleZoom }) {
    const containerRef = useRef(null);
    const isInViewport = useIsInViewport(containerRef);

    useEffect(() => {
        containerRef.current.id = `page=${pageNumber}`
    }, [containerRef?.current]);

    useEffect(() => {
        if (isInViewport) {
            onComeIntoVisibility(pageNumber);
        }
    }, [isInViewport, pageNumber, partNumber, pageCount]);

    return (
        <DocumentScrollPagePane
            partNumber={partNumber}
            pageNumber={pageNumber}
            pageCount={pageCount}
            documentDimensions={documentDimensions}
            isInViewport={isInViewport}
            ref={containerRef}
            onToggleZoom={onToggleZoom}
        >
            {isSectionInViewport && !!children ? children : null}
        </DocumentScrollPagePane>
    );
}