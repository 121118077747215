import { useSelector } from "react-redux";
import usePhoneMode from "./usePhoneMode";

export default function useIsSinglePage() {
    const { isMobile, isLandscape } = usePhoneMode();

    const isSinglePageMode = useSelector((state) => state.documentView.isSinglePageMode) || (isMobile && !isLandscape);
    const isExpandMode = (useSelector((state) => state.documentView.isExpandMode) && !isMobile) || isSinglePageMode;

    return { isSinglePageMode, isExpandMode };
}