import React, { useState } from 'react';
import {
    List,
    Collapse,
    Divider,
    IconButton,
    ListItem
} from '@mui/material';

import TocEntryButton from './TocEntryButton';
import ClearFilterListItem from '../../ClearFilterListItem';
import SearchListItem from '../../SearchListItem';
import { isPageNumberShowing } from '../documentViewHelpers';
import CloseIcon from '@mui/icons-material/Close';
import FlyoutPane from '../FlyoutPane';
import { logFeatureUsage } from '../../../fetch/insightsHelper';
import { useIsSinglePage } from '../../../hooks';

export default function DocumentTableOfContents({ outline, onPageClick, currentPage, hasCover, onCloseClick }) {
    const [workingQuery, setWorkingQuery] = useState('');
    const [executedQuery, setExecutedQuery] = useState('');
    const { isSinglePageMode } = useIsSinglePage();

    const handleExecuteQuery = () => {
        setExecutedQuery(workingQuery.trim());
        setWorkingQuery(workingQuery.trim());
    }

    const handleClearFilters = () => {
        setExecutedQuery('');
        setWorkingQuery('');
    }

    if (outline.children.length == 1 && outline.children[0].title === '' && outline.children[0].children.length > 0) {
        outline = { ...outline, children: outline.children[0].children };
    }

    // Get rid of known bad items
    const filteredChildren = outline.children.filter(it => !!it.pageNumber);
    const expandRoot = filteredChildren.length == 1;

    return (
        <FlyoutPane>
            <List
                sx={{ width: '100%', bgcolor: 'background.paper' }}
                component="nav"
                aria-labelledby="nested-list-subheader"
            >
                <ListItem
                    secondaryAction={
                        <IconButton onClick={onCloseClick} color='primary'>
                            <CloseIcon />
                        </IconButton>
                    }>
                    Table of Contents
                </ListItem>
                <SearchListItem
                    text='Search Table of Contents'
                    workingQuery={workingQuery}
                    onWorkingQueryChanged={setWorkingQuery}
                    onExecutedQuery={handleExecuteQuery}
                />
                <ClearFilterListItem
                    show={!!executedQuery}
                    text='Clear Search'
                    onClick={handleClearFilters}
                />
                <Divider />
                <div style={{ height: 'calc(100vh - 16em)', overflowY: 'auto' }}>
                    <TableOfContentsList
                        items={filteredChildren}
                        setPageIndex={(pageNumber) => {
                            
                            onPageClick(!isSinglePageMode ? pageNumber : pageNumber - 1);
                            logFeatureUsage('LeftTOC');
                        }}
                        openDefault={true}
                        query={executedQuery}
                        currentPage={currentPage}
                        hasCover={hasCover}
                        expandRoot={expandRoot}
                    />
                </div>
            </List>
        </FlyoutPane>
    );
}

const listFilter = (query, it) => {
    return (!!it.title && it.title.toLowerCase().indexOf(query.toLowerCase()) >= 0) ||
        (!!it.children && it.children.filter(listFilter).length > 0);
}

function TableOfContentsList({ items, setPageIndex, query, currentPage, hasCover, expandRoot }) {
    items.sort((a, b) => {
        if (a.pageNumber < b.pageNumber) {
            return -1;
        }

        if (a.pageNumber > b.pageNumber) {
            return 1;
        }

        return 0;
    });

    const listFilter = (it) => {
        return (!!it.title && it.title.toLowerCase().indexOf(query.toLowerCase()) >= 0) ||
            (!!it.children && it.children.filter(listFilter).length > 0);
    }
    const filteredItems = items.filter(listFilter);

    return (
        <>
            {filteredItems.map((item, index) => {
                const { title, pageNumber, children } = item;

                let pageRange = { minimumPageNumber: item.pageNumber, maximumPageNumber: item.pageNumber };

                if (!!children) {
                    pageRange = findPageExteremes(pageRange, children);
                }

                if (!!children && children.length > 0) {
                    return (
                        <TableOfContentsChildList
                            key={index}
                            parent={item}
                            items={children}
                            setPageIndex={setPageIndex}
                            listFilter={listFilter}
                            currentPage={currentPage}
                            pageRange={pageRange}
                            hasCover={hasCover}
                            expandRoot={expandRoot}
                        />
                    );
                }

                return (
                    <TocEntryButton
                        key={index}
                        onClick={() => setPageIndex(hasCover ? pageNumber - 1 : pageNumber)}
                        isPageShowing={isPageNumberShowing(currentPage, pageRange, hasCover)}
                        title={title}
                    />
                )
            })}
        </>
    )
}

function TableOfContentsChildList({ parent, items, setPageIndex, listFilter, currentPage, hasCover, expandRoot }) {
    const [open, setOpen] = useState(expandRoot);
    const { title, pageNumber, children } = parent;

    let pageRange = { minimumPageNumber: parent.pageNumber, maximumPageNumber: parent.pageNumber };

    if (!!children) {
        pageRange = findPageExteremes(pageRange, children);
    }

    const filteredItems = !!items ? items.filter(listFilter) : null;
    if (!title) {
        return null;
    }

    return (
        <>
            <TocEntryButton
                isPageShowing={pageRange.minimumPageNumber != null && pageRange.maximumPageNumber != null && isPageNumberShowing(currentPage, pageRange)}
                title={title}
                isExpandable
                isOpen={open}
                onToggleOpen={() => {
                    setOpen(!open);
                }}
                onClick={() => {
                    setOpen(!open);
                    if (!open) {
                        setPageIndex(hasCover ? pageNumber - 1 : pageNumber);
                    }
                }}
            />

            <Collapse in={open} timeout="auto" unmountOnExit>
                <div style={{ marginLeft: '0.5em' }}>
                    {filteredItems.map((item, index) => {
                        const { title, pageNumber, children } = item;

                        let pageRange = { minimumPageNumber: item.pageNumber, maximumPageNumber: item.pageNumber };

                        if (!!children) {
                            pageRange = findPageExteremes(pageRange, children);
                        }

                        if (!!children && children.length > 0) {
                            return (
                                <TableOfContentsChildList
                                    key={index}
                                    parent={item}
                                    items={children}
                                    setPageIndex={setPageIndex}
                                    listFilter={listFilter}
                                    currentPage={currentPage}
                                    pageRange={pageRange}
                                    hasCover={hasCover}
                                />
                            );
                        }

                        return (
                            <TocEntryButton
                                key={index}
                                onClick={() => setPageIndex(hasCover ? pageNumber - 1 : pageNumber)}
                                isPageShowing={isPageNumberShowing(currentPage, pageRange, hasCover)}
                                title={title}
                            />
                        )
                    })}
                </div>
            </Collapse>
        </>
    );
}

const findPageExteremes = (pageRange, items) => {
    const { minimumPageNumber, maximumPageNumber } = pageRange;

    for (let i = 0; i < items.length; i++) {
        const child = items[i];
        if (child.pageNumber < minimumPageNumber) {
            pageRange.minimumPageNumber = child.pageNumber;
        }

        if (child.pageNumber > maximumPageNumber) {
            pageRange.maximumPageNumber = child.pageNumber;
        }

        if (!!child.children) {
            pageRange = findPageExteremes(pageRange, child.children);
        }
    }

    return pageRange;
}