import React, { useEffect, useRef, useState } from 'react';
import { Button, Drawer, Grid, Paper, Typography, useMediaQuery } from '@mui/material';
import { useDocumentListInfinate, usePreloadBulletinYears, usePreloadDocumentListCounts } from '../../fetch/fetchDocuments';
import DocumentListItemIcon from './DocumentListItemIcon';
import DocumentListItemDetails from './DocumentListItemDetails';
import DocumentListFilters from './filters/DocumentListFilters';
import {
    useIsInViewport, usePhoneMode, useCheckForPassedInFamilyFilter
} from '../../hooks';
import { useSelector } from 'react-redux';
import Spinner from '../spinner/Spinner';
import { storageHelper } from '../../helpers';
import { useTheme } from '@emotion/react';
import ScrollToTop from './ScrollToTop';
import dayjs from 'dayjs';
import ServiceBulletinYearHeader from './ServiceBulletinYearHeader';
import DocumentListScrollView from './DocumentListScrollView';
import DocumentListSubMenu from './subMenu/DocumentListSubMenu';

export default function DocumentList() {
    const theme = useTheme();
    const { families, type: documentType } = useSelector((state) => state.libraryFilters)
    const [filterDrawerOpen, setFilterDrawerOpen] = useState(false);

    useCheckForPassedInFamilyFilter();

    useEffect(() => {
        document.title = 'Mercury Literature';
    }, []);

    usePreloadBulletinYears();
    usePreloadDocumentListCounts();

    const { isMobile } = usePhoneMode();

    const useFloatingFilters = useMediaQuery(theme.breakpoints.down("md"));

    const storedListView = storageHelper.getListView();
    const [stateListView, setListView] = useState(storedListView);
    const listView = documentType != '2' ? stateListView : 'list';

    const take = 12;
    const { documents, size, totalRecords: requestTotalRecords, setSize, isLoading, isValidating } = useDocumentListInfinate(take, listView);

    const loadMoreRef = useRef(null);
    const isInViewport1 = useIsInViewport(loadMoreRef);
    if (isInViewport1 && !isLoading && !isValidating && documents.length < requestTotalRecords) {
        setTimeout(() => setSize(size + 1), 250);
    }

    let containerColumns = { xs: 2, sm: 3, md: 4, lg: 4, xl: 6 };
    if (filterDrawerOpen) {
        containerColumns = { ...containerColumns, md: 2, lg: 3, xl: 5 };
    }
    if (listView === 'medium') {
        containerColumns = { xs: 3, sm: 4, md: 6, lg: 6, xl: 8 };
        if (filterDrawerOpen) {
            containerColumns = { ...containerColumns, md: 4, lg: 5, xl: 7 };
        }
    } else if (listView == 'list') {
        containerColumns = { xs: 1 };
    }

    const areTypesDifferentForGrouping = (type1, type2) => {
        // We want to group Service Bulletins (1) and Diagnostic Manuals (5) together
        if (type1 == 5 || type1 == 6) {
            type1 = 1;
        }

        if (type2 == 5 || type2 == 6) {
            type2 = 1;
        }

        return type1 != type2;
    }

    return (
        <>
            <ScrollToTop />
            {useFloatingFilters ? (
                <Drawer
                    anchor='left'
                    open={filterDrawerOpen}
                    onClose={() => setFilterDrawerOpen(false)}
                >
                    <DocumentListFilters
                        onCloseClick={() => setFilterDrawerOpen(false)}
                    />
                </Drawer>
            ) : null}
            <DocumentListSubMenu
                filtersOpen={filterDrawerOpen}
                onToggleOpenClick={() => setFilterDrawerOpen(!filterDrawerOpen)}
                stateListView={stateListView}
                setListView={setListView}
            />
            <DocumentListScrollView>
                {!isLoading && requestTotalRecords == 0 ? (<Grid item xs={12}>
                    <div style={{ textAlign: 'center', color: '#fff' }}>
                        <Typography variant="h4">
                            No Results
                        </Typography>
                    </div>
                </Grid>) : null}
                {filterDrawerOpen && !useFloatingFilters ? (
                    <Grid item xs='auto' style={{ minWidth: 380 }}>
                        <Paper
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                position: 'fixed',
                                top: theme.appBar.height + theme.appBar.buffer,
                                maxHeight: 'calc(100% - 5em)',
                            }}
                        >
                            <div style={{
                                height: '100%',
                                width: '100%',
                                overflowX: 'hidden',
                                overflowY: 'auto'
                            }}>
                                <DocumentListFilters
                                    onCloseClick={() => setFilterDrawerOpen(false)}
                                />
                            </div>
                        </Paper>
                    </Grid>
                ) : null}
                <Grid item xs>
                    <Grid container columns={containerColumns} spacing={2}>
                        {documents.map((document, index) => {
                            const { hasRead, releaseDate, modificationTimestamp } = document;
                            const isNew = !hasRead && !!releaseDate && dayjs().diff(dayjs(releaseDate || modificationTimestamp), 'day') < 30;

                            if (listView === 'list') {
                                return (
                                    <React.Fragment key={document.partNumber}>
                                        {documentType == '2' && (
                                            index == 0 ||
                                            (index > 0 && document.year != documents[index - 1].year)
                                        ) ? (
                                            <ServiceBulletinYearHeader year={document.year} />
                                        ) : null}
                                        <DocumentListItemDetails isNew={isNew} document={document} small={documentType == '2'} />
                                    </React.Fragment>
                                );
                            } else {
                                return (
                                    <React.Fragment key={document.partNumber}>
                                        {!isMobile && !!families && families.length > 0 &&
                                            index > 0 && areTypesDifferentForGrouping(document.documentTypeId, documents[index - 1].documentTypeId) ? (
                                            <Grid item xs={12}></Grid>
                                        ) : null}
                                        <DocumentListItemIcon key={document.partNumber} isNew={isNew} document={document} />
                                    </React.Fragment>
                                );
                            }
                        })}
                    </Grid>
                </Grid>
                {isLoading ? (<Grid item xs={12}>
                    <div style={{ position: 'relative', left: 'calc(50% - 30px)' }}>
                        <Spinner />
                    </div>
                </Grid>) : null}
                <Grid item xs={12}
                    style={{ display: documents.length < requestTotalRecords ? null : 'none' }}
                    ref={loadMoreRef}>
                    <Paper
                        sx={{
                            p: 2,
                            display: 'flex',
                            flexDirection: 'column',
                            height: '100%'
                        }}
                    >
                        {!isLoading && !isValidating ? (
                            <Button
                                onClick={() => setSize(size + 1)}
                            >
                                Load Next
                            </Button>
                        ) : (
                            <div style={{ position: 'relative', left: 'calc(50% - 30px)' }}>
                                <Spinner />
                            </div>
                        )}
                    </Paper>
                </Grid>
            </DocumentListScrollView>
        </>
    );
}