import { Box, Divider, FormControl, IconButton, InputLabel, List, ListItem, ListItemText, MenuItem, Select } from '@mui/material';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLanguageList } from '../../../fetch/fetchClassificationItems';

import { changeFilter } from '../../../redux/libraryFiltersSlice';
import DocumentSearchListItem from '../DocumentSearchListItem';
import FilterListIcon from '@mui/icons-material/FilterList';
import CloseIcon from '@mui/icons-material/Close';
import { useTheme } from '@emotion/react';
import DocumentTypeFilterGroup from './DocumentTypeFilterGroup';
import BusinessGroupFilterGroup from './BusinessGroupFilterGroup';
import StateFilterGroup from './StateFilterGroup';
import FamilyFilterGroup from './FamilyFilterGroup';

import './DocumentListFilters.css';
import ClearFiltersGroup from './ClearFiltersGroup';

export const defaultFilters = { query: '', families: '', type: 0, group: '', state: 3 };

export default function DocumentListFilters({ onCloseClick }) {
    const theme = useTheme();

    const dispatch = useDispatch();
    const { language } = useSelector((state) => state.libraryFilters);
    const userLanguage = useSelector((state) => state.permissions.language);

    const { languages } = useLanguageList();

    return (
        <Box
            role='presentation'
            sx={{ width: 350 }}
        >
            <List style={{ paddingTop: 0 }}>
                <ListItem onClick={onCloseClick} style={{
                    background: theme.palette.primary.main,
                    color: theme.palette.primary.contrastText,
                    borderRadius: theme.shape.borderRadius,
                    cursor: 'pointer'
                }}>
                    <ListItemText primary={(
                        <IconButton style={{ color: '#fff' }}>
                            <FilterListIcon />
                        </IconButton>)} />

                    <IconButton style={{ color: '#fff' }}>
                        <CloseIcon />
                    </IconButton>
                </ListItem>
                <ListItem style={{ fontWeight: 'bold' }}>
                    Document Filters
                </ListItem>
                <DocumentSearchListItem />
                <ClearFiltersGroup />
            </List>
            <Divider />

            <DocumentTypeFilterGroup />
            <Divider />

            <FamilyFilterGroup />
            <Divider />

            <BusinessGroupFilterGroup />
            <Divider />

            <StateFilterGroup />
            <Divider />

            <List>
                <ListItem>
                    <FormControl fullWidth>
                        <InputLabel id='language-select-label'>Language</InputLabel>
                        <Select
                            labelId='language-select-label'
                            id='language-select-label'
                            value={language || userLanguage}
                            label="Language"
                            onChange={(event) => dispatch(changeFilter({ fieldName: 'language', value: event.target.value }))}
                            sx={{ width: '100%' }}
                        >
                            {languages.map(item => {
                                return (
                                    <MenuItem key={item.id + item.nameLocal} value={item.code}>{item.nameLocal}</MenuItem>
                                );
                            })}
                        </Select>
                    </FormControl>
                </ListItem>
            </List>
        </Box >
    );
}