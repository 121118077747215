import React, { useEffect, useRef, useState } from "react";
import { preloadDocumentPdf, useDocumentPdf } from "../../../fetch/fetchPdf";
import { Document, Page } from 'react-pdf';
import { useIsInViewport } from "../../../hooks";
import DocumentScrollPagePane from "./DocumentScrollPagePane";

export default function DocumentImagePane({ partNumber, pageNumber, hasCover, pageCount, documentDimensions, onComeIntoVisibility, setPageNumber, onToggleZoom }) {
    const containerRef = useRef(null);
    const isInViewport = useIsInViewport(containerRef);

    useEffect(() => {
        containerRef.current.id = `page=${pageNumber}`
    }, [containerRef?.current]);

    const pdfPageNumber = hasCover ? pageNumber + 1 : pageNumber

    const [docRendered, setDocRendered] = useState(false);
    const { pdfData, isLoading: pdfIsLoading } = useDocumentPdf(isInViewport ? partNumber : null, pdfPageNumber);

    useEffect(() => {
        if (isInViewport) {
            onComeIntoVisibility(pageNumber);

            if (isInViewport && pageNumber - 1 > 0) {
                preloadDocumentPdf(partNumber, pdfPageNumber - 1)
            }

            if (isInViewport && pageNumber < pageCount) {
                preloadDocumentPdf(partNumber, pdfPageNumber + 1)
            }
        }
    }, [isInViewport, pageNumber, partNumber, pageCount]);

    return (
        <DocumentScrollPagePane
            partNumber={partNumber}
            pageNumber={pageNumber}
            pageCount={pageCount}
            documentDimensions={documentDimensions}
            isInViewport={isInViewport}
            ref={containerRef}
        >
            {!pdfIsLoading && !!pdfData && isInViewport ? (
                <div style={{ display: !docRendered ? 'none' : null, height: '100%', width: '100%' }} onDoubleClick={onToggleZoom}>
                    <Document file={pdfData} externalLinkTarget="_blank" loading='' onLoadSuccess={(args) => { setTimeout(() => setDocRendered(true), 500); }} onItemClick={({ dest, pageIndex, pageNumber }) => { setPageNumber(pageNumber) }}>
                        <Page loading='' noData='' pageNumber={1} renderTextLayer={true} height={documentDimensions.documentHeight} width={documentDimensions.documentWidth} renderAnnotationLayer={true} />
                    </Document>
                </div>
            ) : null}
        </DocumentScrollPagePane>
    );
}