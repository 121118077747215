import React from "react";
import FilterCollapseGroup from "./FilterCollapseGroup";
import { List } from "@mui/material";
import ListItemCheckbox from "./ListItemCheckbox";
import { useDispatch, useSelector } from "react-redux";
import { changeFilter } from "../../../redux/libraryFiltersSlice";
import { useFamilyNameList } from "../../../fetch/fetchClassificationItems";

export default function FamilyFilterGroup() {
    const dispatch = useDispatch();
    const families = useSelector((state) => state.libraryFilters.families)
    const { familyNames } = useFamilyNameList();

    const handleItemClick = (id) => {
        if (families.indexOf(id) < 0) {
            dispatch(changeFilter({ fieldName: 'families', value: [...families, id] }));
        } else {
            dispatch(changeFilter({ fieldName: 'families', value: families.filter(it => it != id) }));
        }
    }

    return (
        <FilterCollapseGroup
            title='Family'
        >
            <List component="div" disablePadding style={{ maxHeight: '20em', overflowY: 'auto', overflowX: 'hidden' }}>
                {familyNames.map((item, index) => {
                    return (
                        <React.Fragment key={item.id}>
                            {index == 0 || familyNames[index - 1].groupName != item.groupName ? (
                                <div style={{ paddingLeft: '28px', fontWeight: 'bold', fontSize: '0.8em' }}>{item.groupName}</div>
                            ) : null}
                            <ListItemCheckbox
                                key={item.id}
                                id={`family-${item.id}`}
                                text={item.name}
                                isChecked={families.indexOf(item.id) >= 0}
                                onClick={() => { handleItemClick(item.id) }}
                            />
                        </React.Fragment>
                    )
                })}
            </List>
        </FilterCollapseGroup>
    );
}