import React, { useEffect, useMemo, useRef, useState } from "react";
import { parameterHelper } from "../../helpers";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useCheckForPassedInFamilyFilter, useWindowDimensions } from "../../hooks";
import { useDocumentMetadata } from "../../fetch/fetchMetadata";
import { logDocumentOpen } from "../../fetch/insightsHelper";
import { Box, Fab, Grid, Stack } from "@mui/material";
import {
    RelatedDocumentsMenu, DocumentNotFound, DocumentLoading,
    DocumentTableOfContents, DocumentSearch, DocumentViewMenu,
    DocumentScrollViewer, calculateDocumentPaneSizeScrollView
} from "../documentViewCore";
import { preloadPageImage } from "../../fetch/fetchImage";
import { preloadDocumentPdf, preloadDocumentSectionPdf } from "../../fetch/fetchPdf";
import { DocumentScrollSection, ScrollViewCoverPage, ScrollViewTocPage } from "./components";

import 'react-pdf/dist/esm/Page/TextLayer.css';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import { useFixPagePathAndScroll } from "./scrollHelpers";
import ZoomDialog from "../pdfDocumentView/ZoomDialog";
import TextIncreaseIcon from '@mui/icons-material/TextIncrease';
import TextDecreaseIcon from '@mui/icons-material/TextDecrease';

const defaultParameters = { page: null };
export default function DocumentScrollView() {
    const { page: nonHashPageParameter } = parameterHelper.getUrlParameters(defaultParameters);

    const { height: viewportHeight, width: viewportWidth } = useWindowDimensions();
    const userLanguage = useSelector((state) => state.permissions.language);
    const { partNumber } = useParams();
    const scrollRef = useRef(null);


    const [scrollPosition, setScrollPosition] = useState(0);

    const handleScroll = (e) => {
        const { scrollTop } = e.target;
        setScrollPosition(scrollTop);
    };

    useCheckForPassedInFamilyFilter();

    const [documentDimensions, setDocumentDimensions] = useState({ documentHeight: viewportHeight, documentWidth: viewportHeight / 1.3 });

    const [showToc, setShowToc] = useState(false);
    const [showSearch, setShowSearch] = useState(false);
    const [showZoom, setShowZoom] = useState(false);
    const [zoomRotation, setZoomRotation] = useState(0);
    const [zoomScale, setZoomScale] = useState(1);

    const scrollOffsetPerPage = useMemo(() => {
        if (!!documentDimensions?.documentHeight) {
            return scrollPosition / documentDimensions?.documentHeight;
        }

        return 0;
    }, [scrollPosition, documentDimensions?.documentHeight]);

    const handleZoom = (newZoomScale) => {
        newZoomScale = Math.round(newZoomScale * 10) / 10;
        if (newZoomScale < 0.1) {
            newZoomScale = 0.1;
        }

        setZoomScale(newZoomScale);
    }

    const handleToggleZoom = (event) => {
        event.stopPropagation();
        let newZoomScale = 1;
        if (zoomScale == 1) {
            newZoomScale = 2;// TODO Max
        }

        handleZoom(newZoomScale);

        const newDocDimensions = calculateDocumentPaneSizeScrollView(
            showToc || showSearch,
            false,
            viewportWidth,
            viewportHeight,
            !!pageRatio ? pageRatio : 1.3,
            newZoomScale
        )

        setDocumentDimensions(newDocDimensions);
        if (!!scrollRef?.current) {
            setTimeout(() => { scrollRef.current.scrollTop = newDocDimensions.documentHeight * scrollOffsetPerPage; }, 50);
        }
    }

    const { metadata, isLoading: isMetadataLoading, error } = useDocumentMetadata(partNumber);
    const { hasCover, title, outline, sections, pages, pageCount } = metadata;
    let pageRatio = null;
    if (!!pages && pages.length > 0) {
        pageRatio = pages[(!hasCover ? 0 : 1)].pageRatio;
    }

    const currentPage = !!window.location.hash ? +window.location.hash.replace('#page=', '') : 1;
    const nextPage = currentPage + 1;

    const currentSection = sections?.filter(it => it.start <= currentPage && it.end >= currentPage)[0];
    const currentPageOffset = currentSection?.start - 1;

    const nextSection = sections?.filter(it => it.start <= nextPage && it.end >= nextPage)[0];
    const nextPageOffset = nextSection?.start - 1;

    useMemo(() => {
        if (!!userLanguage && !error) {
            const { activationKey } = parameterHelper.getUrlParameters({ activationKey: null });
            logDocumentOpen(partNumber, userLanguage, currentSection?.fileName, !!activationKey);
        }
    }, [partNumber, userLanguage, currentSection?.fileName, error]);

    useEffect(() => {
        const newDocDimensions = calculateDocumentPaneSizeScrollView(
            showToc || showSearch,
            false,
            viewportWidth,
            viewportHeight,
            !!pageRatio ? pageRatio : 1.3,
            zoomScale
        )
        setDocumentDimensions(newDocDimensions);
        if (!!scrollRef?.current) {
            setTimeout(() => { scrollRef.current.scrollTop = newDocDimensions.documentHeight * scrollOffsetPerPage; }, 50);
        }
    }, [viewportHeight, viewportWidth, showToc, showSearch, pageRatio, zoomScale]);

    useEffect(() => {
        if (!!title) {
            document.title = title;
        } else {
            document.title = 'Mercury Literature';
        }
    }, [title]);

    useFixPagePathAndScroll(nonHashPageParameter, !!nonHashPageParameter && !!metadata && !isMetadataLoading);

    if (!!error) {
        return (
            <DocumentNotFound error={error} />
        );
    }

    if (isMetadataLoading || hasCover === undefined || !pageRatio) {
        return (
            <DocumentLoading documentHeight={documentDimensions.documentHeight} />
        );
    }

    const setPageNumber = (pageNumber) => {
        preloadPageImage(partNumber, pageNumber);
        var section = sections.filter(it => !it.overSizeFile && pageNumber >= it.start && pageNumber <= it.end)[0];
        if (!!section) {
            preloadDocumentSectionPdf(partNumber, section.fileName);
        } else {
            preloadDocumentPdf(partNumber, hasCover ? pageNumber + 1 : pageNumber)
        }

        window.location.hash = `page=${pageNumber}`;
    }

    return (
        <Box component="main" sx={{ p: 0, overflow: 'hidden', maxHeight: '100%', width: '100%', marginTop: '8px' }}>
            <RelatedDocumentsMenu partNumber={partNumber} />
            <Grid container>
                <Grid item xs={12} xl={12}>
                    <Stack direction='row' spacing={2} justifyContent='center'>
                        {showToc ? (
                            <DocumentTableOfContents
                                outline={outline}
                                onPageClick={setPageNumber}
                                currentPage={currentPage}
                                onCloseClick={() => setShowToc(false)}
                            />
                        ) : null}
                        {showSearch ? (
                            <DocumentSearch
                                onPageClick={setPageNumber}
                                currentPage={currentPage}
                                partNumber={partNumber}
                                onCloseClick={() => setShowSearch(false)}
                            />
                        ) : null}

                        <DocumentScrollViewer width={documentDimensions.documentWidth} ref={scrollRef} onScroll={handleScroll}>
                            <ScrollViewCoverPage
                                partNumber={partNumber}
                                hasCover={hasCover}
                                documentDimensions={documentDimensions}
                            />
                            <ScrollViewTocPage
                                partNumber={partNumber}
                                hasCover={hasCover}
                                documentDimensions={documentDimensions}
                                outline={outline}
                                pageRatio={pageRatio}
                                setPageNumber={setPageNumber}
                            />
                            {sections.map((section, sectionIndex) => {
                                const { start, end } = section;
                                const pagesInSection = pages.filter(it => it.pageNumber >= start && it.pageNumber <= end);

                                let previousSection = null;
                                if (sectionIndex > 0) {
                                    previousSection = sections[sectionIndex - 1];
                                }

                                let nextSection = null;
                                if (sectionIndex < sections.length - 1) {
                                    nextSection = sections[sectionIndex + 1];
                                }

                                return (
                                    <DocumentScrollSection
                                        key={sectionIndex}
                                        hasCover={hasCover}
                                        section={section}
                                        partNumber={partNumber}
                                        pages={pagesInSection}
                                        pageCount={pageCount}
                                        documentDimensions={documentDimensions}
                                        setPageNumber={setPageNumber}
                                        previousSection={previousSection}
                                        nextSection={nextSection}
                                        onToggleZoom={handleToggleZoom}
                                        scrollRef={scrollRef}
                                    />
                                );
                            })}
                        </DocumentScrollViewer>
                    </Stack>
                </Grid>

                <div style={{ position: 'absolute', left: '2em' }}>
                    <Fab
                        color="primary"
                        aria-label="Zoom in"
                        size='small'
                        onClick={() => handleZoom(zoomScale + 0.1)}
                        disabled={zoomScale >= 2}
                    >
                        <TextIncreaseIcon />
                    </Fab>

                    <Fab
                        color="primary"
                        aria-label="Zoom out"
                        size='small'
                        onClick={() => handleZoom(zoomScale - 0.1)}
                        disabled={zoomScale <= 1}
                    >
                        <TextDecreaseIcon />
                    </Fab>
                </div>
            </Grid>
            <DocumentViewMenu
                setPageNumber={setPageNumber}
                hasCover={hasCover}
                currentPage={currentPage}
                pageCount={pageCount}
                showToc={!!outline}
                onToggleToc={() => {
                    setShowToc(!showToc);
                    // if it was not show toc that means it's now show search so dimiss search so we can use the same space
                    if (!showToc) {
                        setShowSearch(false);
                    }
                }}
                onToggleSearch={() => {
                    setShowSearch(!showSearch);
                    // if it was not show search that means it's now show search so dimiss toc so we can use the same space
                    if (!showSearch) {
                        setShowToc(false);
                    }
                }}
                showingZoom={showZoom}

                onClickZoom={() => setShowZoom(!showZoom)}
                zoomRotation={zoomRotation}
                setZoomRotation={setZoomRotation}
                zoomScale={zoomScale}
                setZoomScale={setZoomScale}

                partNumber={partNumber}
                hasMultipleSections={sections?.length > 1}
                currentSection={currentSection}
                nextSection={nextSection}
                pageMappings={pages}
            />
            <ZoomDialog
                open={showZoom}
                onClose={() => setShowZoom(false)}
                partNumber={partNumber}
                currentSection={currentSection}
                currentPage={currentPage}
                currentPageOffset={currentPageOffset}
                zoomRotation={zoomRotation}
                zoomScale={zoomScale}
                isSinglePageMode={true}
                pageRatio={pageRatio}
                nextSection={nextSection}
                nextPageOffset={nextPageOffset}
                hasCover
            />
        </Box>
    );
}