import { useTheme } from "@emotion/react";
import { Button, ListItem, ListItemText } from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { changeFilter, clearFilters } from "../../../redux/libraryFiltersSlice";
import CancelIcon from '@mui/icons-material/Cancel';
import { useFamilyNameList } from "../../../fetch/fetchClassificationItems";

export const defaultFilters = { query: '', families: '', type: '', group: '', state: 3 };

export default function ClearFiltersGroup() {
    const dispatch = useDispatch();
    const { familyNames } = useFamilyNameList();

    const {
        families,
        type: documentType,
        group: businessGroup,
        state: documentState
    } = useSelector((state) => state.libraryFilters);
    const litStates = useSelector((state) => state.permissions.litStates)
    const businessGroups = useSelector((state) => state.permissions.businessGroups)

    const showType = documentType != defaultFilters.type;
    const typeHasClearAll = showType;

    const showFamily = families.join(',') != defaultFilters.families
    const familyHasClearAll = showFamily && !typeHasClearAll

    const showGroup = businessGroup != defaultFilters.group
    const groupHasClearAll = showGroup && !typeHasClearAll && !familyHasClearAll

    const showState = documentState != defaultFilters.state;
    const stateHasClearAll = showState && !typeHasClearAll && !familyHasClearAll && !groupHasClearAll

    let filteredDocumentType = null;
    if (documentType != '') {
        if (documentType == '2') {
            filteredDocumentType = 'Bulletins';
        } else {
            filteredDocumentType = 'Manuals';
        }
    }

    let filteredDocumentState = null;
    if (documentState != defaultFilters.state && !!litStates) {
        filteredDocumentState = litStates.filter(it => it.id == documentState).map(it => it.description)[0];
    }

    return (
        <>
            <ClearFilterTitleListItem show={showType} showClear={typeHasClearAll}>
                <ClearFilterTitle>Document Type</ClearFilterTitle>
            </ClearFilterTitleListItem>
            <ClearFilterSelectionListItem
                show={!!filteredDocumentType}
                onClick={() => dispatch(changeFilter({ fieldName: 'type', value: defaultFilters.type }))}
            >
                {filteredDocumentType}
            </ClearFilterSelectionListItem>

            <ClearFilterTitleListItem show={showFamily} showClear={familyHasClearAll}>
                <ClearFilterTitle>Family</ClearFilterTitle>
            </ClearFilterTitleListItem>
            {families.map(item => (
                <ClearFilterSelectionListItem
                    key={item}
                    show
                    onClick={() => dispatch(changeFilter({ fieldName: 'families', value: families.filter(it => it != item) }))}
                >
                    {familyNames.filter(it => it.id == item)[0]?.name}
                </ClearFilterSelectionListItem>
            ))}

            <ClearFilterTitleListItem show={showGroup} showClear={groupHasClearAll}>
                <ClearFilterTitle>Group</ClearFilterTitle>
            </ClearFilterTitleListItem>
            {businessGroup.map(item => (
                <ClearFilterSelectionListItem
                    key={item}
                    show
                    onClick={() => dispatch(changeFilter({ fieldName: 'group', value: businessGroup.filter(it => it != item) }))}
                >
                    {businessGroups.filter(it => it.id == item)[0]?.name}
                </ClearFilterSelectionListItem>
            ))}

            <ClearFilterTitleListItem show={showState} showClear={stateHasClearAll}>
                <ClearFilterTitle>State</ClearFilterTitle>
            </ClearFilterTitleListItem>
            <ClearFilterSelectionListItem
                show={!!filteredDocumentState}
                onClick={() => dispatch(changeFilter({ fieldName: 'state', value: defaultFilters.state }))}
            >
                {filteredDocumentState}
            </ClearFilterSelectionListItem>
        </>
    )
}

function ClearFilterSelectionListItem({ show, onClick, children }) {
    const theme = useTheme();

    if (!show) {
        return null;
    }

    return (
        <ListItem sx={{ pt: 0, pb: 0 }} onClick={onClick} style={{ cursor: 'pointer' }}>
            <CancelIcon style={{ color: theme.palette.primary.main }} />
            &nbsp;
            {children}
        </ListItem>
    );
}

function ClearFilterTitleListItem({ show, showClear, children }) {
    if (!show) {
        return null;
    }

    return (
        <ListItem
            sx={{ pt: 0, pb: 0 }}
            secondaryAction={<ClearFiltersButton show={showClear} />}
        >
            {children}
        </ListItem>
    );
}

function ClearFilterTitle({ children }) {
    const theme = useTheme();
    return (
        <ListItemText style={{ color: theme.palette.secondary.main }}>{children}</ListItemText>
    );
}

function ClearFiltersButton({ show }) {
    const dispatch = useDispatch();

    if (!show) {
        return null;
    }

    return (
        <Button
            style={{
                textTransform: 'none',
                textDecoration: 'underline'
            }}
            onClick={() => dispatch(clearFilters())}
        >
            Clear Filters
        </Button>
    );
}