import React from 'react';
import { Grid, Paper } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import './DocumentListItemIcon.css';
import { useDocumentTypeList } from '../../fetch/fetchClassificationItems';
import TypeTitle from './TypeTitle';
import NewBanner from './NewBanner';
import usePhoneMode from '../../hooks/usePhoneMode';
import ProgressiveThumbnail from './ProgressiveThumbnail';
import VideoThumbnail from './VideoThumbnail';

export default function DocumentListItemIcon({ document, isNew }) {
    const navigate = useNavigate();
    const { isMobile } = usePhoneMode();
    const { partNumber, documentTypeId, title, language } = document;
    const { documentTypes } = useDocumentTypeList();

    const sanitizeBulletinPartNumber = (partNumber) => {
        // get rid of the prefix (sob, smc, shs, etc)
        let value = partNumber.substring(3);

        if (language != 'en' && partNumber.indexOf('_') > 0) {
            value = value.substring(0, value.indexOf('_'));
        }

        return value;
    }

    return (
        <Grid item xs={1} className='document-list-item' style={{ position: 'relative' }}>
            <Paper
                sx={{
                    p: 1.5,
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                    overflow: 'hidden',
                    cursor: 'pointer'
                }}
                onClick={(event) => {
                    event.stopPropagation();
                    navigate(documentTypeId != 7 ? `/literature/view/${partNumber}` : `/literature/video/${partNumber}`);
                }}
            >
                {isNew ? <NewBanner>New</NewBanner> : null}
                {documentTypeId != 2 && documentTypeId != 7 ? (
                    <TypeTitle isNew={isNew} documentTypeId={document.documentTypeId}>
                        {documentTypes.filter(it => it.id == document.documentTypeId)[0]?.name}
                    </TypeTitle>
                ) : null}
                {documentTypeId == 2 ? (
                    <TypeTitle
                        isNew={isNew}
                        documentTypeId={document.documentTypeId}
                        fontSize={isMobile && document.partNumber.indexOf('R') > 0 ? '0.9em' : null}
                    >
                        Bulletin {sanitizeBulletinPartNumber(document.partNumber)}
                    </TypeTitle>
                ) : null}
                {documentTypeId != 7 ? (
                    <div>
                        <ProgressiveThumbnail
                            partNumber={partNumber}
                            documentTypeId={documentTypeId}
                            title={title}
                        />
                    </div>
                ) : (
                    <VideoThumbnail
                        videoId={partNumber}
                        title={title}
                        showTitle
                    />
                )}
            </Paper>
        </Grid>
    );
}