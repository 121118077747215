import React from "react";
import ContentContainer from "./contentContainer";
import { Box, Grid } from "@mui/material";
import PageTitle from "./pageTitle";
import './g3SystemRequirements.css';

export default function G3SystemRequirements() {
    return (
        <ContentContainer>
            <PageTitle title='System Requirements' />

            <Box component="main" sx={{ p: 3, width: '100%' }} className="system-requirements">
                <Grid container>
                    <Grid item xs={12}>
                        <h3>Computer Diagnostic System G3</h3>
                        <p>
                            CDS G3 is a standalone PC based program that complements the original Mercury Computer Diagnostic System (CDS) by providing diagnostic support for select engines and Mercury Joystick Piloting systems. Additionally, all configuration functions necessary for preparing these systems for delivery are also supported. CDS G3 allows for CAN based multiple processor communication through a clean, easy to navigate interface.
                            We recommend the <a href="https://na.panasonic.com/us/computers-tablets-handhelds/computers/laptops/toughbook-55">Panasonic Toughbook 55</a>
                        </p>
                    </Grid>
                </Grid>

                <Grid container>
                    <Grid item xs={6}>
                        <h3>Laptop System Requirements</h3>
                        <ul>
                            <li>
                                <ul>
                                    <li>Intel i5 Processor, 2.4 ghz or higher</li>
                                    <li>8&nbsp;GB RAM or better</li>
                                    <li>
                                        <p style={{ margin: '0in 0in 0pt' }}>Screen Resolution 1920 X 1080 or better</p>
                                    </li>
                                    <li>128 GB solid state hard drive or better</li>
                                    <li>Qty. 1 or more USB ports 2.0 or 3.0</li>
                                    <li>Windows 10 or 11&nbsp;</li>
                                    <li>An Internet Connection</li>
                                </ul>
                                <p><em>** A single USB port plus a USB port hub may be used.<br /></em></p>
                                <p>Microsoft Surface Pro X Laptops that have "USB-C" device,&nbsp;will not load SmartCraft Interface Drivers (Kvaser interface Drivers) thus will not work with G3.&nbsp;&nbsp;the USB-C adaptor to USB still will not work</p>
                            </li>
                        </ul>
                        <p><em>&nbsp;</em></p>
                    </Grid>
                    <Grid item xs={6}>
                        <h3>Features</h3>
                        <ul className="feature-list">
                            <li>View, record and play engine/module data</li>
                            <li>View faults and run history</li>
                            <li>Run diagnostics tests</li>
                            <li>Configure engine and controls</li>
                            <li>Reflash modules</li>
                        </ul>
                    </Grid>
                </Grid>
            </Box>
        </ContentContainer>
    );
}